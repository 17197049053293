import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Lookup } from "../models/Lookup";
import { AbstractRestService } from "@shared/services/abstract.service";

@Injectable({
  providedIn: "root",
})
export class AdhocService extends AbstractRestService<any> {
  baseUrl = environment.apiUrl;
  emailOrMobileNumber: any;
  InternationalMobileNumber: string;
  registerModel: any;
  contactInviteId: string;
  constructor(private http: HttpClient) {
    super("adhoc");
  }
  setContactInviteId(v) {
    this.contactInviteId = v;
  }
  getContactInviteId() {
    return this.contactInviteId;
  }
  addEmail(model: any) {
    return this.http.post(this.baseUrl + "Adhoc/subscribeemail", model);
  }

  addforgottenEmail(model: any) {
    this.emailOrMobileNumber = model;
  }

  getTheForgottenEmail() {
    return this.emailOrMobileNumber;
  }

  addEmailOrMobileNumber(em: any) {
    this.emailOrMobileNumber = em;
  }

  getEmailOrMobileNumber() {
    return this.emailOrMobileNumber;
  }
  addInternationalMobileNumber(v) {
    this.InternationalMobileNumber = v;
  }
  getInternationalMobileNumber() {
    return this.InternationalMobileNumber;
  }

  addRegisterModel(model) {
    this.registerModel = model;
    this.registerModel.Password = "";
    this.registerModel.ConfirmPassword = "";
  }

  getRegisterModel() {
    return this.registerModel;
  }

  submitMessage(model: any) {
    return this.http.post(this.baseUrl + "Help/ContactUs", model);
  }

  async getLookupByParentAndLanguageCode(
    parentId: string,
    languageCode: string
  ): Promise<Lookup[]> {
    return this.http
      .get<Lookup[]>(
        this.baseUrl + "Adhoc/lookuplist/" + parentId + "/" + languageCode
      )
      .toPromise();
  }
}
