import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { JWTLocalInterceptor } from './interceptors/jwt-interceptor';
import { AdhocService } from "./services/adhoc.service";
import { AlertifyService } from "./services/alertify.service";
import { LocationService } from "./services/location.service";
import { SharedService } from "./services/shared.service";
import { StorageService } from "./services/storage.service";
import { AuthInterceptor } from "./interceptors/auth-interceptor";
@NgModule({
  providers: [
    AdhocService,
    AlertifyService,
    LocationService,
    SharedService,
    StorageService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JWTLocalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class CoreModule { }
