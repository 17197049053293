import { HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG, } from "@angular/platform-browser";
import {
  NgModule,
  Injectable,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  APP_INITIALIZER,
  LOCALE_ID
} from "@angular/core";
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import localeAr from '@angular/common/locales/ar';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { AppRoutingModule } from "./app-routing.module";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { MessagingService } from "./core/services/messaging.service";
import { AppComponent } from "./app.component";
import { JwtModule } from "@auth0/angular-jwt";
import { CoreModule } from "./core/core.module";
import { environment } from "src/environments/environment";
import { NgxSpinnerModule } from "ngx-spinner";
import { AsyncPipe, registerLocaleData, LocationStrategy, HashLocationStrategy } from "../../node_modules/@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EventEmitterService } from "@shared/services/event-emitter.service";
import { ServiceLocator } from "@shared/services/locater.service";
///////////////////////////////////
import { DragDropModule } from '@angular/cdk/drag-drop';

export function tokenGetter() {
  return localStorage.getItem("token") || sessionStorage.getItem("token");
}
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/');
}
import { ProfileService } from "@modules/dashboard/sections/my-account/profile.service";
import { ProfileSettingsService } from "@modules/dashboard/sections/profile-settings/profile-settings.service";
import { SharedDataService } from "@shared/services/shared-data.service";
export const configLookUp = (_configLookup: SharedDataService) => {
  return () => _configLookup
};
export const configCountries = (_configCountries: SharedDataService) => {
  return () => _configCountries
};
import { NgxImageCompressService } from "ngx-image-compress";
import * as Hammer from 'hammerjs';

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: true },
    rotate: { enable: true },
    swipe: { direction: Hammer.DIRECTION_ALL },
    pan: { direction: Hammer.DIRECTION_ALL },
    press: { direction: Hammer.DIRECTION_ALL },
  };
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatNativeDateModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["localhost:5000", "demo.matensa.com", "matensa.com"],
        blacklistedRoutes: ["localhost:5000/api/auth", "demo.matensa.com/api/auth", "matensa.com/api/auth"],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    CoreModule,
    NgxSpinnerModule,
    HammerModule,
    DragDropModule
  ],
  providers: [
    // Set the default locale and timezone for mat-datepicker
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    NgxImageCompressService,
    EventEmitterService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    AsyncPipe,
    MessagingService,
    {
      provide: APP_INITIALIZER,
      useFactory: configLookUp,
      deps: [SharedDataService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configCountries,
      deps: [SharedDataService],
      multi: true,
    },
    ProfileService,
    ProfileSettingsService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.setInjector(this.injector);
    registerLocaleData(localeAr, 'ar');

  }
}
