import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SharedService } from '@core/services/shared.service';
import { ProfileSettings } from './ProfileSettings';
import { Utils } from '@shared/helper/utils';
import { Profile } from '../my-account/profile';
import { ProfileService } from '../my-account/profile.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileSettingsService {
  privacySettings;
  method = 'User';
  //myAccountRoles:Observable<any[]>;
  myAccountRoles;
  get apiUrl() {
    return environment.apiUrl;
  }
  get actionUrl() {
    return this.apiUrl + this.method;
  }
  constructor(
    public http: HttpClient,
    public _SharedService: SharedService,
    public _ProfileService: ProfileService
  ) {
    //  if(!Utils.isNullOrUndefinedOrEmpty(this._SharedService.getLoggedInUser()))
    //  this._setRolesPermissions();
  }
  ///////////////////////////////////////////////////
  _getProfilePrivacy() {
    return this.http.get<any>(this.actionUrl + '/GetProfilePrivacy');
  }

  public get ProfileInfo(): Observable<Profile> {
    return this._ProfileService.profileData
  }
  async _setProfilePrivacy() {
    await this._getProfilePrivacy().subscribe(x => {
      if (x['Flag'] == 0) {
        this.privacySettings = x['Result'];
      }
    })
  }
  public _profilePrivacy(): Observable<ProfileSettings> {
    return this.privacySettings
  }
  _updateProfilePrivacy(data) {
    return this.http
      .post<any>(this.actionUrl + '/UpdateProfilePrivacy', data);
  }
  ///////////////////////////////////////////////////
  async _setMyAccountRoles() {
    let getLoggedInUser = this._SharedService.getLoggedInUser();
    if (Utils.isNullOrUndefinedOrEmpty(getLoggedInUser)) {
      return;
    } else {

      await this._getAccountRoles().subscribe(x => {
        this.myAccountRoles = x['Result'];

      })
    }
  }
  public _myAccountRoles(): Observable<any[]> {
    return this.myAccountRoles;
  }
  _removeAccountRole(id) {
    let findIndex = this.myAccountRoles.findIndex(x => x.Id == id);
    if (findIndex >= 0)
      this.myAccountRoles.splice(findIndex, true)
  }
  _IsAccepted(id) {
    let findIndex = this.myAccountRoles.findIndex(x => x.Id == id);
    if (findIndex >= 0)
      this.myAccountRoles[findIndex].IsAccepted = true;
  }
  ///////////////////////////////////////////////////
  _getNotificationsSettings() {
    return this.http
      .get<any>(this.actionUrl + '/GetNotificationSettings');
  }
  _updateNotificationsSettings(data) {
    return this.http
      .post<any>(this.actionUrl + '/UpdateNotificationSettings', data);
  }
  ///////////////////////////////////////////////////
  _getBlockedList() {
    return this.http
      .get<any>(this.actionUrl + '/BlockedAccountList');
  }
  /////////////////////////////////////////
  SystemPermissions: any[] = [];
  _setRolesPermissions() {
    let getLoggedInUser = this._SharedService.getLoggedInUser();
    if (Utils.isNullOrUndefinedOrEmpty(getLoggedInUser)) {
      return;
    }
    else {
      this._getRolesPermissions().then((x) => {
        this.SystemPermissions = x['Result'];
      })
    }
  }
  _getRolesPermissions(withPermissions: boolean = true) {
    let params = {
      withPermissions: withPermissions
    }
    //GetRolesPermissions

    return this.http.get<any>(this.actionUrl + '/GetRoles', { params: params }).toPromise()
  }
  _getAccountPermissions(accountId) {
    return this.http.get<any>(this.actionUrl + '/GetAccountRolesByAccountRfId/' + accountId)
  }
  _getAccountRoles(withPermissions: boolean = true) {

    return this.http.get(this.actionUrl + '/GetAccountRoles', { params: { withPermissions: withPermissions } })
  }
  _addAccountPermissions(data) {
    return this.http
      .post<any>(this.actionUrl + '/SaveAccountRole', data);
  }
  _acceptAccountRole(accountId) {
    return this.http
      .post<any>(this.actionUrl + '/AcceptAccountRole', {
        AccountRoleId: accountId
      });
  }
  /////////////////////////////////////////
  _saveTerms(data) {
    return this.http
      .post<any>(this.apiUrl + 'TermsConditions/SavePersonalTermsConditions', data);
  }
  _getTerms(id) {
    return this.http
      .get<any>(this.apiUrl + 'TermsConditions/GetPersonalTermsConditions', { params: { accountId: id } });
  }
}