import { Guid } from "./../models/Guid";
import { environment } from "./../../../environments/environment";
import { catchError, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { SharedService } from "@core/services/shared.service";
import { StorageService } from "@core/services/storage.service";
import { Utils } from "@shared/helper/utils";
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: "root",
})
export class JWTLocalInterceptor implements HttpInterceptor {
  private requests: string[] = [];
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private storageService: StorageService
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.sharedService.setRequests(request);

    if (request.method === 'POST' || request.method === 'PUT') {
      // this.shiftDates(request.body);
    }

    this.sharedService.isLoading.next(true);
    let languageCode = this.sharedService.getSelectedLanguage();

    if (!Utils.isNullOrUndefinedOrEmpty(languageCode) && languageCode.toLowerCase().indexOf("ar") != -1) {
      languageCode = "ar-lb";
    }

    let data = JSON.stringify(request.body);
    let array = ['ExpiryDate', 'StartDatetime', 'EndDatetime', 'RegistrationDate', 'DateOfBirth', 'EstablishedDate', 'StartDate', 'EndDate', 'FirstPaymentDate', 'LastPaymentDate', 'PaymentDueBy', 'EventDate', 'CreatedDate'];

    if (request.body instanceof FormData) {
      for (const pair of request.body.entries()) {
        if (pair[0] == 'dtos' || pair[0] == 'dto') {
          let input = JSON.parse(pair[1].toString());

          if (!Array.isArray(input)) {
            input = [JSON.parse(pair[1].toString())];
          }

          const updatedInput = input.map(item =>
            this.updateProperty(item, array, "00:00Z")
          );

          const message = JSON.stringify(updatedInput);
          data = CryptoJS.enc.Utf8.parse(message);
        }
      }
    }

    const currentDate = new Date();
    const iv = CryptoJS.enc.Utf8.parse('bV7zviumyooUwZtF');
    const key = CryptoJS.enc.Utf8.parse('tb6EbH2F7dCPXfWZvVz7YshYhexvGMM5');
    var ticks = ((currentDate.getTime() * 10000) + 621355968000000000);
    const encryptedMilliseconds = CryptoJS.AES.encrypt(ticks.toString(), key, { iv: iv }).toString();
    const encrypteddata = CryptoJS.HmacSHA512(data, key).toString();

    const closeRequest: HttpRequest<any> = request.clone({
      // url: request.url + "?culture=" + languageCode,
      url: request.url,
      withCredentials: true,
      setHeaders: {
        'Request-Id': encryptedMilliseconds,
        'X-Payload-Signature': encrypteddata,
        'Accept-Language': this.sharedService.getSelectedLanguage(),
        Authorization: `Bearer ${this.storageService.getStorage("token")}`,
        //Authorization: `${this.storageService.getStorage("token")}`,
        AccountId: this.sharedService.getCurrentAccount() || Guid.empty
      },
    });

    return next.handle(closeRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(request);
            // do stuff with response if you want 
          }
        },
        (err: any) => {
          this.removeRequest(request);
        },
        () => {
          if (!Utils.isNullOrUndefinedOrEmpty(this.storageService.getSessionStorage("valid_token"))) {
            const currentTimePlusHalfHour = new Date(
              new Date().setMinutes(
                new Date().getMinutes() + parseInt(environment.sessionLife)
              )
            ).getTime();

            this.storageService.setSessionStorage(
              "valid_token",
              currentTimePlusHalfHour.toString()
            );
          }
        }
      )
    );
  }

  shiftDates(body) {
    if (body === null || body === undefined) {
      return body;
    }
    if (typeof body !== 'object') {
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        //body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes(), value.getSeconds()));
        body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
      }
      else if (typeof value === 'object') {
        //this.shiftDates(value);
      }
    }
  }
  removeRequest(req: HttpRequest<any>) {
    this.sharedService.removeRequest(req);
  }

  // Recursive function to update nested properties
  // updateProperty(obj: any, target: string, replacement: string): any {
  //   for (const key in obj) {
  //     if (obj.hasOwnProperty(key)) {
  //       if (typeof obj[key] === 'object') {
  //         obj[key] = this.updateProperty(obj[key], target, replacement);
  //       } else if (key === target && obj[key] && obj[key].endsWith("00:00.000Z")) {
  //         obj[key] = obj[key].replace("00:00.000Z", replacement);
  //       }
  //     }
  //   }
  //   return obj;
  // }

  updateProperty(obj: any, targets: string[], replacement: string): any {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object') {
          obj[key] = this.updateProperty(obj[key], targets, replacement);
        } else if (targets.includes(key) && obj[key] && obj[key].endsWith("00:00.000Z")) {
          obj[key] = obj[key].replace("00:00.000Z", replacement);
        }
      }
    }
    return obj;
  }
}
