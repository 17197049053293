import { Utils } from "../../shared/helper/utils";
import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable()
export class SharedService {
  public isLoading = new BehaviorSubject(false);
  public withLoader = new BehaviorSubject(true);
  private _selectedCurrency = new BehaviorSubject<any>("");
  public _selectedLanguage = new BehaviorSubject<any>("");
  private _countryCode = new BehaviorSubject<any>("");
  // private _profileImageChanged = new BehaviorSubject<any>("");
  // private _userProfileChanged = new BehaviorSubject<any>("");
  // private _notificationCount = new BehaviorSubject<any>("");
  // private _contactDetailsPageTitle = new BehaviorSubject<any>("");
  // private _pendingRemindersChanged = new BehaviorSubject<any>("");
  // private _pendingRemindersForContactChanged = new BehaviorSubject<any>("");
  // private _pendingFriendRequestChanged = new BehaviorSubject<any>("");
  // private _navigateToAgreementChanged = new BehaviorSubject<any>("");
  public _notificationCounter = new BehaviorSubject<any>("");
  // private _pendingReceivedMessagesChanged = new BehaviorSubject<any>("");
  // private _pendingReceivedReceiptsChanged = new BehaviorSubject<any>("");

  _selectedLanguage$: Observable<any>;
  _selectedCurrency$: Observable<any>;
  _countryCode$: Observable<any>;
  // _profileImageChanged$: Observable<any>;
  // _userProfileChanged$: Observable<any>;
  // _notificationCount$: Observable<any>;
  // _contactDetailsPageTitle$: Observable<any>;
  // _pendingRemindersChanged$: Observable<any>;
  // _pendingRemindersForContactChanged$: Observable<any>;
  // _pendingFriendRequestChanged$: Observable<any>;
  // _navigateToAgreementChanged$: Observable<any>;
  _notificationCounter$: Observable<any>;
  // _pendingReceivedMessagesChanged$: Observable<any>;
  // _pendingReceivedReceiptsChanged$: Observable<any>;

  loggedInUser: any;
  SelectedContact: any;
  
  isRememderMe: boolean = false;

  constructor(
    private Http:HttpClient
  ) {
    this._selectedLanguage$ = this._selectedLanguage.asObservable();
    this._selectedCurrency$ = this._selectedCurrency.asObservable();
    this._countryCode$ = this._countryCode.asObservable();
    // this._profileImageChanged$ = this._profileImageChanged.asObservable();
    // this._userProfileChanged$ = this._userProfileChanged.asObservable();
    // this._notificationCount$ = this._notificationCount.asObservable();
    // this._contactDetailsPageTitle$ = this._contactDetailsPageTitle.asObservable();
    // this._pendingRemindersChanged$ = this._pendingRemindersChanged.asObservable();
    // this._pendingRemindersForContactChanged$ = this._pendingRemindersForContactChanged.asObservable();
    // this._pendingFriendRequestChanged$ = this._pendingFriendRequestChanged.asObservable();
    // this._navigateToAgreementChanged$ = this._navigateToAgreementChanged.asObservable();
    this._notificationCounter$ = this._notificationCounter.asObservable();
    // this._pendingReceivedMessagesChanged$ = this._pendingReceivedMessagesChanged.asObservable();
    // this._pendingReceivedReceiptsChanged$ = this._pendingReceivedReceiptsChanged.asObservable();
  }

  setNotificationCounter(data) {
    this._notificationCounter.next(data);
  }

  // contactDetailNavigation(data) {
  //   this._navigateToAgreementChanged.next(data);
  // }
  setSelectedLanguage(data) {
    //if (this.isRememderMe) localStorage.setItem("selected_language", data);
    //else sessionStorage.setItem("selected_language", data);
    localStorage.setItem("selected_language", data);
    localStorage.setItem("lang", data);
    this._selectedLanguage.next(data);
  }
  getSelectedLanguage(): string {
    //if (this.isRememderMe) {
      if (
        !Utils.isNullOrUndefinedOrEmpty(
          localStorage.getItem("selected_language")
        )
      )
        return localStorage.getItem("selected_language");
    // } else {
    //   if (
    //     !Utils.isNullOrUndefinedOrEmpty(
    //       sessionStorage.getItem("selected_language")
    //     )
    //   )
    //     return sessionStorage.getItem("selected_language");
    // }
    return "en";
  }

  setSelectedCurrency(data) {
    if (this.isRememderMe) {
      localStorage.setItem("selected_currency", data.Id);
      localStorage.setItem("selected_currency_text", data.Text);
    } else {
      sessionStorage.setItem("selected_currency", data.Id);
      sessionStorage.setItem("selected_currency_text", data.Text);
    }
    this._selectedCurrency.next(data);
  }


  getLoggedInUser() {
    if (this.isRememderMe) {
      let returnRes = !Utils.isNullOrUndefinedOrEmpty(this.loggedInUser)
      ? this.loggedInUser
      : new Object({AccountId:localStorage.getItem("AccountId")});
      return (!Utils.isNullOrUndefinedOrEmpty(returnRes) && !Utils.isNullOrUndefinedOrEmpty(returnRes.AccountId))
      ? returnRes
      : null;
    }
    else {
      // return !Utils.isNullOrUndefinedOrEmpty(this.loggedInUser)
      // ? this.loggedInUser
      // : new Object({AccountId:sessionStorage.getItem("AccountId")});
      let returnRes = !Utils.isNullOrUndefinedOrEmpty(this.loggedInUser)
      ? this.loggedInUser
      : new Object({AccountId:sessionStorage.getItem("AccountId")});
      return (!Utils.isNullOrUndefinedOrEmpty(returnRes) && !Utils.isNullOrUndefinedOrEmpty(returnRes.AccountId))
      ? returnRes
      : null;
    }
  }

  getCurrentAccount() {
    if (this.isRememderMe) {
      return localStorage.getItem("AccountId");
    }
    else {
      return sessionStorage.getItem("AccountId");
    }
  }
  setCurrentAccount(AccountId) {
    if (this.isRememderMe) {
      localStorage.setItem("AccountId",AccountId);
    }
    else {
      sessionStorage.setItem("AccountId",AccountId);
    }
  }

  setLoggedInUser(user: any) {
    if (typeof user == "undefined" || user == null) {
        if (this.isRememderMe) localStorage.removeItem("AccountId");
        else sessionStorage.removeItem("AccountId");
    } 
    //   if (this.isRememderMe) localStorage.removeItem("logged_in_user");
    //   else sessionStorage.removeItem("logged_in_user");
    // if (this.isRememderMe)
    //   localStorage.setItem("logged_in_user", JSON.stringify(user));
    // else sessionStorage.setItem("logged_in_user", JSON.stringify(user));
    this.loggedInUser = user;
    this.setCurrentAccount(user.AccountId);
    //this._userProfileChanged.next(user);
  }

  setWithLoader(withLoader) {
    this.withLoader.next(withLoader)
  }
  public requests: string[] = [];
  setRequests(req) {
    const i = this.requests.indexOf(req.url);
    if (i < 0) {
      this.requests.push(req.url);
    }
    this.showHideLoader();
  }
  removeRequest(req) {
    const i = this.requests.indexOf(req.url);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.showHideLoader();
  }
  showHideLoader() {
    this.isLoading.next((this.requests.length > 0));
  }

  ////
  set_countryCodeNext(code) {
    this._countryCode.next(code);
  }
  CountryFetch:any = null;
  set_countryCode() {
    if(Utils.isNullOrUndefinedOrEmpty(this.CountryFetch)) {
      this.CountryFetch = this.Http.get(environment.apiUrl+'Misc/GetCurrentCountryInfo').toPromise().then(x=>{
        if(x['CountryCode']) {
          this.set_countryCodeNext(x['CountryCode'])
        }
      })
    }
    // if(Utils.isNullOrUndefinedOrEmpty(code)) {
    //   if(Utils.isNullOrUndefinedOrEmpty(this.CountryFetch)) {
    //     this.CountryFetch = fetch('https://api.ipregistry.co/?key=ymcnz2ri5ppvc900')
    //     .then(function (response) {
    //         return response.json();
    //     })
    //     .then( (payload) => {
    //       if(payload.location) {
    //         let code = payload.location.country.code;
    //         this.set_countryCodeNext(code)
    //       }
    //       else {
    //         this.set_countryCodeNext(defaultCode)
    //       }
    //     });
    //   }
      
    // }
    // else {
    //   this.set_countryCodeNext(code)
    // }
  }

}
