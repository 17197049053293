import { Utils } from "../../shared/helper/utils";
import { CurrencyEnum } from "../../shared/enumeration/CurrencyEnum";
import { BaseComponent } from "@shared/components/base/base.component";
import { Injectable, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AbstractRestService } from "@shared/services/abstract.service";
import { LookupEnums } from "@shared/enumeration/LookupEnum";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { StorageService } from "@core/services/storage.service";
import { SharedService } from "@core/services/shared.service";
import { ServiceLocator } from "@shared/services/locater.service";
import { AlertifyService } from "@core/services/alertify.service";
import { MessagingService } from "@core/services/messaging.service";
import { ProfileService } from "@modules/dashboard/sections/my-account/profile.service";
import * as add from '../../../assets/scripts/scripts.js'
declare function add(x): any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: "root",
})
export class AuthService extends AbstractRestService<any> {
  isRemembered: boolean = true;
  isLogin = false;
  redirectUrl: string;
  translate: TranslateService;
  constructor(
    public router: Router,
    public profile: ProfileService,
    private alertifyService: AlertifyService,
    private sharedService: SharedService,
    private storageService: StorageService,
    private _MessagingService: MessagingService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {

    super("auth");


    setTimeout(() => {
      if (this.profile.profileData) {
        console.log(this.profile.profileData);
        add(this.profile.profileData);
      }
    }, 1100);
    this.translate = ServiceLocator.getInjector().get(TranslateService);
    if (window.addEventListener) {
      window.addEventListener("storage", this.sessionStorage_transfer, false);
    } else {
      (<any>window).attachEvent("onstorage", this.sessionStorage_transfer);
    }
    if (Utils.isNullOrUndefinedOrEmpty(sessionStorage)) {
      localStorage.setItem("getSessionStorage", "foobar");
      localStorage.removeItem("getSessionStorage");
    }
    if (this.isUserLoggedIn()) {

      this.isLogin = true;
    }
    // if(this.LoggedInUser){



    //   this.lastAction(Date.now());
    //   this.check();
    //   this.initListener();
    //   this.initInterval();
    // }

  }
  get LoggedInUser() {
    // console.warn(this.sharedService.getLoggedInUser())
    return this.sharedService.getLoggedInUser();
  }
  getLastAction() {
    return localStorage.getItem('lastAction');
  }
  lastAction(value) {
    localStorage.setItem('lastAction', JSON.stringify(value))
  }
  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }
  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 5000);
    })
  }
  reset() {
    this.lastAction(Date.now());
  }
  check() {
    const now = Date.now();
    const timeLeft = parseInt(this.getLastAction()) + (5) * 60 * 1000;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;
    this.ngZone.run(() => {
      if (isTimeout && this.isLogin) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('lastAction');

        this.alertifyService.alertMessage(
          this.translate.instant('session_expired_desc'),
          this.translate.instant('session_expired'),
        );
        this.logout()
      }
    });
  }
  isUserLoggedIn(): string {
    return environment.firebaseConfig.apiKey;
  }
  // login(model: any) {
  //   return this.posttWithAction("login", model).then((response: any) => {
  //     this.isRemembered = model.rememberMe;
  //     const user = response;
  //     if (user) {
  //       this.onSuccessLogin(user, this.isRemembered);
  //     }
  //     return user;
  //   });
  // }

  onSuccessLogin(user: any, isRemembered: boolean) {
    this.sharedService.isRememderMe = isRemembered;
    this.storageService.setStorage("token", user.AuthToken);
    this.storageService.saveRefreshToken(user.RefreshToken);
    //this.decodedToken = this.jwtHelper.decodeToken(user.AuthToken);
    this.storageService.setDecodedToken();
  }

  register(model: any) {
    return this.posttWithAction("register", model);
  }

  CheckMobileNumber(MobileObj) {
    return this.posttWithAction("CheckMobileNumber", MobileObj);
  }

  isAuthenticated() {
    const token = this.storageService.getToken("token");
    let getLoggedInUser = this.sharedService.getLoggedInUser();
    if (
      !Utils.isNullOrUndefinedOrEmpty(
        this.storageService.getSessionStorage("valid_token")
      )
    ) {
      if (
        parseInt(this.storageService.getSessionStorage("valid_token")) -
        new Date().getTime() <
        0
      ) {
        this.alertifyService.alertMessage(
          this.translate.instant('session_expired_desc'),
          this.translate.instant('session_expired')
        );
        this.storageService.setSessionStorage("valid_token", "");
        //this.logout();
        return false;
      }
    }
    else if (Utils.isNullOrUndefinedOrEmpty(getLoggedInUser)) return false;
    else if (Utils.isNullOrUndefinedOrEmpty(token)) return false;
    return true;
  }

  testToken() {
    return this._http.get(environment.apiUrl + 'Auth/GetClientDeviceInfo');
  }

  AccessControl() {
    return this._http.get(environment.apiUrl + 'AccessControl/FeaturesStatus');
  }
  AppInfo() {
    return this._http.get(environment.apiUrl + 'Help/GetAppInfo');
  }
  refreshToken() {
    // return this._http.post(environment.apiUrl + 'Auth/RefreshToken', {
    //   refreshToken: token
    // }, httpOptions);
    let Obj = {}
    return this._http.get(environment.apiUrl + 'Auth/RefreshToken', httpOptions);
  }

  _destroyLogin() {
    this.storageService.clearStorage();
  }

  _logOutSys() {
    let Obj = {};
    return this._http.post(environment.apiUrl + 'Auth/Logout', Obj)
  }
  logout(withRedirect: string = '') {

    this._logOutSys().subscribe(x => {
      this.logout_noauth(withRedirect);
    });
  }
  logout_noauth(withRedirect: string = '') {
    BaseComponent.staticSelectedCurrency = LookupEnums.CurrencyEnum.USD.toString();
    BaseComponent.staticSelectedCurrencyText = CurrencyEnum[CurrencyEnum.USD];
    // unsubscribe Tokens
    this._MessagingService.unSubScribeAllTokens();
    this.storageService.clearLogin(withRedirect);


    // if (this.router.url.indexOf('ItemDetails') > -1) {
    //   let url = this.router.url;
    //   let url_seg = url.split('/');
    //   let accountId = url_seg[3];
    //   let id = url_seg[5];
    //   this.router.navigate(['/auth/profile/' + accountId + '/ItemDetails/' + id])
    // } else {
    //   this.router.navigate(['/auth/login']);
    // }

    let redirect_login = true;

    if (this.router.url.indexOf('ItemDetails') > -1) {
      redirect_login = false;
      let url = this.router.url;
      let url_seg = url.split('/');
      let accountId = url_seg[3];
      let id = url_seg[5];
      //this.router.navigate(['/auth/profile/' + accountId + '/ItemDetails/' + id])
    }
    if (this.router.url.indexOf('connection-profile') > -1) {
      redirect_login = false;
      let url = this.router.url;
      let url_seg = url.split('/');
      let accountId = url_seg[3];
      //this.router.navigate(['/auth/connection-profile/' + accountId])
    }

    if (this.router.url.indexOf('catalogue') > -1 || this.router.url.indexOf('cat') > -1) {

      redirect_login = false;
      let url = this.router.url;
      let url_seg = url.split('/');
      let accountId = url_seg[3];
      //this.router.navigate(['/auth/connection-profile/' + accountId])
    }


    if (this.router.url.indexOf('reservation') > -1 || this.router.url.indexOf('res') > -1) {
      redirect_login = false;
      let url = this.router.url;
      let url_seg = url.split('/');
      let accountId = url_seg[3];
      //this.router.navigate(['/auth/connection-profile/' + accountId])
    }


    if (redirect_login) {
      this.router.navigate(['/auth/login'], { queryParams: { redirectUrl: withRedirect } });
    }
  }

  //transfers sessionStorage from one tab to another
  sessionStorage_transfer = function (event) {
    if (!event) {
      event = window.event;
    } // ie suq
    if (!event.newValue) return; // do nothing if no value to work with
    if (event.key == "getSessionStorage") {
      // another tab asked for the sessionStorage -> send it
      localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
      // the other tab should now have it, so we're done with it.
      localStorage.removeItem("sessionStorage"); // <- could do short timeout as well.
    } else if (event.key == "sessionStorage" && !sessionStorage.length) {
      // another tab sent data <- get it.
      const data = JSON.parse(event.newValue);
      for (const key in data) {
        sessionStorage.setItem(key, data[key]);
      }
    }
  };

  getSerialNumber() {
    return 'SRL123';
  }
  getDevice() {
    //var header = [
    //navigator.platform,
    //navigator.userAgent,
    //navigator.appVersion,
    //navigator.vendor
    //];
    return navigator.platform;
  }
  getIpAddress() {
    // this._http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
    //   return res.ip;
    // });
    let num1 = this.generateRandomInteger(10);
    let num2 = this.generateRandomInteger(10);
    let num3 = this.generateRandomInteger(10);
    let num4 = this.generateRandomInteger(10);
    let ip = num1 + '.' + num2 + '.' + num3 + '.' + num4;
    return ip;
  }
  generateRandomInteger(max) {
    return Math.floor(Math.random() * max) + 1;
  }
  getBrowser() {
    return (navigator.vendor != '') ? navigator.vendor : navigator.appCodeName;
  }
  getAgent() {
    return 'Test';
    // if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) return 'Opera';
    // else if(navigator.userAgent.indexOf("Chrome") != -1 ) return 'Chrome';
    // else if(navigator.userAgent.indexOf("Safari") != -1) return 'Safari';
    // else if(navigator.userAgent.indexOf("Firefox") != -1 ) return 'Firefox';
    // else if((navigator.userAgent.indexOf("MSIE") != -1 )) return 'IE';
    // else return 'unknown';
  }
  _mobileSecurityQuestions(mobile) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("mobile", mobile);
    mobile = mobile.replace('+', '%2B', mobile);
    return this._http
      .get<any>(this.apiUrl + this.actionUrl + '/SecurityQuestionsList?mobile=' + mobile).toPromise();
  }
}