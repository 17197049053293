import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AlertifyService } from "@core/services/alertify.service";

import { Utils } from "@shared/helper/utils";
import { SharedService } from "@core/services/shared.service";
import { AuthService } from "@modules/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private sharedService: SharedService,
    private alertifyService: AlertifyService,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 || err.status === 405) {
          this.authService.logout_noauth();
          this.sharedService.showHideLoader();
        } else if (err.error === "NOT_VERIFIED") {
          this.sharedService.showHideLoader();
          return throwError(err);
        } else {
          const error = err.error || err.statusText || err.message;

          if (err.status == 0) {
            this.router.navigate(['error-page']);
            this.alertifyService.error(this.translate.instant('unable_to_connect') + ' : ' + err.statusText);
          } else if (err.status == 405 || err.status == 404) {
            if (!Utils.isNullOrUndefinedOrEmpty(error.Message)) {
              this.alertifyService.error(error.Message);
            } else if (!Utils.isNullOrUndefinedOrEmpty(err.message)) {
              this.alertifyService.error(err.message);
            }
          } else if (err.status == 401) {
            // auth interceptor will take place
          } else {
            let errorMsg = error.Message;
            if (!Utils.isNullOrUndefinedOrEmpty(errorMsg)) {
              this.alertifyService.error(errorMsg);
            }
          }

          this.sharedService.showHideLoader();
          return throwError(error);
        }
      })
    );
  }
}
