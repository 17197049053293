import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { AuthService } from '@modules/auth/auth.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';

@Component({
  selector: 'app-auth-nav',
  templateUrl: './auth-nav.component.html',
  styleUrls: ['./auth-nav.component.css']
})
export class AuthNavComponent extends BaseComponent implements OnInit {

  @Output() loginClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() forgotPasswordPageClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() resetPasswordPageClicked: EventEmitter<boolean> = new EventEmitter();
  @Input() isLogin: boolean;

  public navbarCollapsed = true;

  @Input() LogoColor = 'white';

  constructor(
    public authService: AuthService, private dateAdapter: DateAdapter<Date>

  ) {
    super();
  }

  ngOnInit() {

  }

  onChangeLanguage(language) {
    this.sharedService.setSelectedLanguage(language);
    this.dateAdapter.setLocale(language);
    this.selectedLanguage = language;
    ///RELOAD//
    var currentUrl = window.location.href;
    window.location.href = currentUrl;
  }

  loginToggle() {
    this.router.navigate(['/login']);
  }



  cancelRegisterClicked() {
    this.homeToggle();
  }

  cancelForgotPasswordClicked() {
    this.loginToggle();
  }

  cancelResetPasswordClicked() {
    this.loginToggle();
  }

  successRegisterClicked() {
    this.homeToggle();
  }

  homeToggle() {
    this.router.navigate(['/']);
  }

  loggedIn() {
    return this.authService.isAuthenticated();
  }

  public get logged() {
    return this.authService.isAuthenticated();
  }
}
