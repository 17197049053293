<div class="dashboard-wrapper ">
    <div class="dashboard-bluebg"></div>
    <div class="dashboard-content">
        <div class="not-found-page">
          <div class="mt-5 pt-5">
            <a href="javascript:history.go(-1)">  <div class="back-button" ><i class="far fa-long-arrow-left"></i></div></a>
        </div>
        <div class="text-center mt-5 pt-2">
            <div class="mt-5 pt-5"><svg id="Group_20005" data-name="Group 20005" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="310.844" height="216.271" viewBox="0 0 310.844 216.271">
             <defs>
               <clipPath id="clip-path">
                 <rect id="Rectangle_16672" data-name="Rectangle 16672" width="310.844" height="216.271" fill="none"/>
               </clipPath>
             </defs>
             <g id="Group_20005-2" data-name="Group 20005" clip-path="url(#clip-path)">
               <path id="Path_9554" data-name="Path 9554" d="M61.934,527.732c34.2,0,61.934,2.106,61.934,4.7s-27.729,4.7-61.934,4.7S0,535.034,0,532.436s27.729-4.7,61.934-4.7" transform="translate(0 -320.869)" fill="#e6e6e6"/>
               <path id="Path_9555" data-name="Path 9555" d="M120.772,71.275c10.778-4.584,24.2-1.68,37.313.336.122-2.441,1.6-5.413.052-7.116-1.882-2.069-1.709-4.244-.576-6.43,2.9-5.592-1.253-11.542-5.442-16.489a9.28,9.28,0,0,0-7.744-3.25l-7.76.555a9.293,9.293,0,0,0-8.252,6.643c-1.853,2.52-2.845,5.033-2.221,7.533-2.775,1.9-3.242,4.186-1.994,6.768a3.89,3.89,0,0,1-.048,4.745,21.907,21.907,0,0,0-3.257,6.47Z" transform="translate(-73.431 -23.288)" fill="#2f2e41"/>
               <path id="Path_9556" data-name="Path 9556" d="M422.041,454.771H202.366A7.366,7.366,0,0,1,195,447.406q119.448-13.882,234.407,0A7.366,7.366,0,0,1,422.041,454.771Z" transform="translate(-118.563 -267.809)" fill="#2f2e41"/>
               <path id="Path_9557" data-name="Path 9557" d="M429.407,216.659,195,216.226l27.167-45.712.13-.217V69.554a9.31,9.31,0,0,1,9.31-9.311H391.5a9.31,9.31,0,0,1,9.311,9.31V170.991Z" transform="translate(-118.563 -36.629)" fill="#3f3d56"/>
               <path id="Path_9558" data-name="Path 9558" d="M283.145,76.823a3.037,3.037,0,0,0-3.033,3.033v91.856a3.037,3.037,0,0,0,3.033,3.033H444.76a3.036,3.036,0,0,0,3.033-3.033V79.856a3.037,3.037,0,0,0-3.033-3.033Z" transform="translate(-170.312 -46.71)" fill="#fff"/>
               <path id="Path_9559" data-name="Path 9559" d="M274.577,357.585a1.3,1.3,0,0,0-1.181.757l-8.371,18.2a1.3,1.3,0,0,0,1.181,1.843H442.243A1.3,1.3,0,0,0,443.4,376.5l-9.1-18.2a1.293,1.293,0,0,0-1.162-.719Z" transform="translate(-161.066 -217.417)" fill="#2f2e41"/>
               <path id="Path_9560" data-name="Path 9560" d="M489.318,63.006a1.95,1.95,0,1,1-1.95,1.95,1.95,1.95,0,0,1,1.95-1.95" transform="translate(-296.327 -38.309)" fill="#fff"/>
               <path id="Path_9561" data-name="Path 9561" d="M442.927,419.485a1.3,1.3,0,0,0-1.255.962l-2.1,7.8a1.3,1.3,0,0,0,1.255,1.638H480.76a1.3,1.3,0,0,0,1.228-1.725l-2.7-7.8a1.3,1.3,0,0,0-1.228-.875Z" transform="translate(-267.239 -255.053)" fill="#2f2e41"/>
               <path id="Path_9562" data-name="Path 9562" d="M442.949,337.135v1.733H264.306l.134-.216v-1.517Z" transform="translate(-160.702 -204.983)" fill="#2f2e41"/>
               <path id="Path_9563" data-name="Path 9563" d="M660.29,0a30.33,30.33,0,1,1-30.33,30.33A30.33,30.33,0,0,1,660.29,0" transform="translate(-383.025)" fill="#1c008a"/>
               <path id="Path_9564" data-name="Path 9564" d="M697.722,82.19H672.591a1.734,1.734,0,0,1-1.733-1.733V65.292a1.734,1.734,0,0,1,1.733-1.733h25.131a1.734,1.734,0,0,1,1.733,1.733V80.457a1.734,1.734,0,0,1-1.733,1.733m-25.131-16.9V80.457h25.132V65.292Z" transform="translate(-407.892 -38.645)" fill="#fff"/>
               <path id="Path_9565" data-name="Path 9565" d="M703.037,43.785h-15.6V36.853c0-5.021,3.28-8.666,7.8-8.666s7.8,3.645,7.8,8.666Zm-13.865-1.733H701.3v-5.2c0-4.082-2.494-6.933-6.066-6.933s-6.066,2.851-6.066,6.933Z" transform="translate(-417.973 -17.138)" fill="#fff"/>
               <path id="Path_9566" data-name="Path 9566" d="M704.647,81.8a1.733,1.733,0,1,1-1.733,1.733,1.733,1.733,0,0,1,1.733-1.733" transform="translate(-427.382 -49.734)" fill="#fff"/>
               <path id="Path_9567" data-name="Path 9567" d="M455.994,191.917h-124.9a2.316,2.316,0,0,1-2.313-2.313V158.621a2.316,2.316,0,0,1,2.313-2.313h124.9a2.316,2.316,0,0,1,2.313,2.313V189.6a2.316,2.316,0,0,1-2.313,2.313m-124.9-34.684a1.389,1.389,0,0,0-1.388,1.388V189.6a1.389,1.389,0,0,0,1.388,1.388h124.9a1.389,1.389,0,0,0,1.388-1.388V158.621a1.389,1.389,0,0,0-1.388-1.388Z" transform="translate(-199.903 -95.038)" fill="#e6e6e6"/>
               <path id="Path_9568" data-name="Path 9568" d="M369.576,174.659a9.422,9.422,0,1,1-9.422,9.422,9.422,9.422,0,0,1,9.422-9.422" transform="translate(-218.979 -106.195)" fill="#e6e6e6"/>
               <path id="Path_9569" data-name="Path 9569" d="M437.262,182.672a1.57,1.57,0,1,0-.006,3.141h74.035a1.57,1.57,0,1,0,0-3.141Z" transform="translate(-264.905 -111.067)" fill="#e6e6e6"/>
               <path id="Path_9570" data-name="Path 9570" d="M437.267,206.708a1.57,1.57,0,0,0,0,3.141h31.855a1.57,1.57,0,1,0,0-3.141Z" transform="translate(-264.91 -125.682)" fill="#e6e6e6"/>
               <path id="Path_9571" data-name="Path 9571" d="M227.255,187.447l-23.089,12.275-.292-10.23a68.378,68.378,0,0,0,21.336-7.014l2.415-5.966a4.044,4.044,0,1,1,6.518,4.465Z" transform="translate(-123.958 -105.785)" fill="#ffb8b8"/>
               <path id="Path_9572" data-name="Path 9572" d="M119.35,365.122h0a4.536,4.536,0,0,1,.576-3.67L125,353.668a8.864,8.864,0,0,1,11.5-3.034c-2.132,3.628-1.834,6.812.736,9.578a46.1,46.1,0,0,0-10.951,7.465,4.536,4.536,0,0,1-6.933-2.555" transform="translate(-72.469 -212.588)" fill="#2f2e41"/>
               <path id="Path_9573" data-name="Path 9573" d="M200.2,310.278h0a8.7,8.7,0,0,1-7.4,4.225l-33.7.255-1.461-8.475,14.906-4.384-12.567-9.645,13.736-16.075,24.95,23.254a8.7,8.7,0,0,1,1.529,10.845" transform="translate(-95.851 -167.921)" fill="#2f2e41"/>
               <path id="Path_9574" data-name="Path 9574" d="M124.635,331.976h-7.891c-7.086-48.566-14.3-97.268,7.014-115.445l25.135,4.092-3.215,21.336L131.65,258.033Z" transform="translate(-66.64 -131.654)" fill="#2f2e41"/>
               <path id="Path_9575" data-name="Path 9575" d="M140.421,517.454h0a4.537,4.537,0,0,1-3.67-.576l-8.568-1.152a8.864,8.864,0,0,1-3.034-11.5c3.629,2.131,6.812,1.834,9.578-.737,1.954,3.946,5.175,3.705,8.25,7.03a4.536,4.536,0,0,1-2.555,6.933" transform="translate(-75.489 -306.13)" fill="#2f2e41"/>
               <path id="Path_9576" data-name="Path 9576" d="M173.005,102.487,158.392,98.98c2.427-4.968,2.627-10.5,1.461-16.367l9.937-.292a76.329,76.329,0,0,0,3.215,20.166" transform="translate(-96.305 -50.052)" fill="#ffb8b8"/>
               <path id="Path_9577" data-name="Path 9577" d="M163.477,167.768c-10.638,7.249-18.154.248-23.89-10.553.8-6.61-.493-14.52-2.884-23.114a15.86,15.86,0,0,1,1.671-12.365,15.491,15.491,0,0,1,7.935-6.607l12.567,5.261c10.671,8.7,12.772,18.12,8.768,28.057Z" transform="translate(-82.76 -70)" fill="#ffa72e"/>
               <path id="Path_9578" data-name="Path 9578" d="M87.143,133.821l-11.691,6.138L96.2,152.234l2.887,7.122a3.778,3.778,0,0,1-2.081,4.92q-.093.038-.188.071a3.778,3.778,0,0,1-5-3.35l-.292-4.964-26.374-8.7A6.168,6.168,0,0,1,63.6,136.388L84.8,121.838Z" transform="translate(-37.039 -74.079)" fill="#ffb8b8"/>
               <path id="Path_9579" data-name="Path 9579" d="M133.165,130.2c-4.876-2.137-9.309.184-14.906,2.046l-.877-15.49c5.557-2.962,10.855-3.76,15.782-1.461Z" transform="translate(-71.371 -69.294)" fill="#ffa72e"/>
               <path id="Path_9580" data-name="Path 9580" d="M158.053,54.291a9.33,9.33,0,1,1-9.33,9.33,9.33,9.33,0,0,1,9.33-9.33" transform="translate(-90.426 -33.01)" fill="#ffb8b8"/>
               <path id="Path_9581" data-name="Path 9581" d="M168.775,62.1c-9.238.9-16.276-.606-20.775-4.908V53.732h19.991Z" transform="translate(-89.986 -32.67)" fill="#2f2e41"/>
             </g>
           </svg>
           
              </div>
            <div class="mt-3">Unable to connect to server, please try again later</div>
        </div>
    </div>
    </div>
 </div>
