import { Component, OnInit } from '@angular/core';
import { AuthService } from '@modules/auth/auth.service';
import { BaseComponent } from '@shared/components/base/base.component';

@Component({
  selector: 'app-email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.css']
})
export class EmailVerifiedComponent extends BaseComponent implements OnInit {
  LoggedIn: boolean = false;
  constructor(
    protected AuthService: AuthService
  ) {
    super();
    if (this.AuthService.isAuthenticated())
      this.LoggedIn = true;
  }



}
