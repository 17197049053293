import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@modules/auth/auth.service';
import { BaseComponent } from '@shared/components/base/base.component';
import { Utils } from '@shared/helper/utils';
import { Subscription } from 'rxjs';
import { ReminderService } from '../reminder.service';

@Component({
  selector: 'app-global-check-reminder',
  templateUrl: './global-check-reminder.component.html',
  styleUrls: ['./global-check-reminder.component.css']
})
export class GlobalCheckReminderComponent extends BaseComponent implements OnInit {
  private sub: Subscription;
  showNotificationModal:boolean = false;
  id;
  constructor(
    private ReminderService :ReminderService,
    private route:ActivatedRoute,
    private _AuthService:AuthService
  ) {
    super();
  }
  ngOnInit(): void {
    this.sub = this.route.queryParams.subscribe(params => {
      this.id=params['id'];
    }); 
    this.getreminder(this.id);
  }

  getreminder(id) {
    this.ReminderService.Getreminder(id).subscribe(res => {
      if(res['Flag'] == 0) {
        if(!Utils.isNullOrUndefinedOrEmpty(res['Result'])) {
          this.ReminderService.GetreminderLink(res['Result']);
          this.router.navigate([this.ReminderService.reminderLink]);
        }
        else {
          this.showNotificationModal = true;
        }
      }
      else {
        // do nothing
      }
    })
  }
  doContinue() {
    this.showNotificationModal = false;
    this.router.navigate(['/dashboard']);
  }
  doLogout() {
    this.showNotificationModal = false;
    this._AuthService.logout('/ViewNotification?id='+this.id);
  }

  
}
