import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
//import { Observable } from 'rxjs';
import { Utils } from '@shared/helper/utils';
import { LookupEnums } from '@shared/enumeration/LookupEnum';
import { Guid } from '@core/models/Guid';


@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  get apiUrl() {
    //if (environment.production) return this.configService.config.apiUrl;
    return environment.apiUrl;
  }
  faqURL = this.apiUrl + 'Help/';
  lookupData: any[] = [];
  countriesData: any[] = [];
  currencyRates: any[] = [];
  systemRoles: any[] = [];
  IndividualDocTypeId = LookupEnums.LookupCategoryEnum.IndividualIdentificationDocuments;
  BusinessDocTypeId = LookupEnums.LookupCategoryEnum.BusinessIdentificationDocuments;
  constructor(
    private http: HttpClient
  ) {
    if (!Utils.isNullOrUndefinedOrEmpty(localStorage.getItem('Lookups'))) {
      this.lookupData = JSON.parse(localStorage.getItem('Lookups'));
    } else {
      this.getLookUpData()
    }
    if (!Utils.isNullOrUndefinedOrEmpty(localStorage.getItem('Countries'))) {
      this.countriesData = JSON.parse(localStorage.getItem('Countries'));
      console.warn(this.countriesData)
    } else {
      this.getCountriesNationalities()
    }
  }
  getLookUpData() {
    // this.getCurrencyRates()
    //this.apiUrl+'Lookup/AllLookupList'
    //./assets/data/lookup.json
    return this.http
      .get<any>(this.apiUrl + 'Lookup/AllLookupList')
      .toPromise()
      .then(x => {
        localStorage.setItem('Lookups', JSON.stringify(x['Result']))
        this.lookupData = x['Result'];
      });
  }
  getCountriesNationalities() {

    //.get<any>(this.apiUrl+'Lookup/GetCountriesNationalities')
    // ./assets/data/countries.json
    return this.http
      .get<any>(this.apiUrl + 'Lookup/GetCountriesNationalities')
      .toPromise()
      .then(x => {
        localStorage.setItem('Countries', JSON.stringify(x['Result']))
        this.countriesData = x['Result'];
      });
  }

  getVatRate(cur?) {
    return 11;
  }
  getCurRate(from, to?) {
    let CurRate = {
      Id: 0,
      FromCurrencyLKPId: 0,
      ToCurrencyLKPId: 0,
      Value: 0,
      MarketValue: 0
    };
    if (Utils.isNullOrUndefinedOrEmpty(to)) {
      to = this.getDefaultCurrency();
    }
    let rates = [];
    if (!Utils.isNullOrUndefinedOrEmpty(this.currencyRates)) {
      rates = this.currencyRates.filter(function (item) {
        if (item.FromCurrencyLKPId == from && item.ToCurrencyLKPId == to) {
          return true;
        }
      });
    }
    if (rates.length > 0) {
      CurRate = rates[0];
    }
    return CurRate;
  }
  getDefaultCurrency() {
    let currencies = this.searchLookUpData(LookupEnums.LookupCategoryEnum.Currency);
    let DefaultCur = 120;
    currencies.forEach(x => {
      if (x['Note'] == 'IsDefault') DefaultCur = x['Id'];
    })
    return DefaultCur;
  }
  searchLookUpData(parentId, withOther: boolean = false) {
    let res = [];
    let Data = this.lookupData.find(x => x.ParentId == parentId);
    if (!Utils.isNullOrUndefinedOrEmpty(Data)) {
      res = Data['LookUps'];
    }
    if (withOther) {
      let Data = this.lookupData.find(x => x.ParentId == LookupEnums.LookupCategoryEnum.OtherIdentificationDocumentsParent);
      let otherPption = Data.LookUps.find(x => x.Id == LookupEnums.LookupCategoryEnum.OtherIdentificationDocuments);
      res.push(otherPption)
    }
    return res;
  }
  getMaxFileSizeForValidation() {
    let ParentMaxSizeValidations = this.searchLookUpData(LookupEnums.LookupCategoryEnum.Constraints);
    let MaxSize = Number(ParentMaxSizeValidations.find(x => x.Id == LookupEnums.Constraints.DocumentMaxSize).Note);
    return MaxSize;
  }
  getFAQ() {
    return this.http
      .post<any>(this.faqURL + 'FAQList',
        {
          "IsWithAnswers": true,
          "PageIndex": 1,
          "PageSize": 10,
          "Orderby": "",
          "IsDesc": true,
          "Search": "",
          "StartDatetime": "2021-08-10T12:03:23.798Z",
          "EndDatetime": "2021-08-10T12:03:23.798Z"
        }
      )
      .toPromise();
  }
  getTutorials() {
    return this.http
      .post<any>(this.faqURL + 'TutorialsList',
        {
          "PageIndex": 1,
          "PageSize": 10,
          "Orderby": "",
          "IsDesc": true,
          "Search": "",
          "StartDatetime": "2022-11-04T10:01:12.054Z",
          "EndDatetime": "2022-11-04T10:01:12.054Z"
        }
      )
      .toPromise();
  }
  getSharedProfileLink(MatensaId) {
    return 'https://dashboard.matensa.com/' + '/#/dashboard/connection-profile/' + MatensaId + '/profile';
  }
  getShareAppLink() {
    return 'https://www.matensa.com/';
  }
  businessdocs;
  getAgreementDocuments() {
    let res = [];
    let Obj;
    let IndividiualDocs = this.searchLookUpData(this.IndividualDocTypeId);
    IndividiualDocs.forEach(x => {
      if (x['Note']) {
        Obj = JSON.parse(x['Note']);
        if (Obj.MandatoryOnAgreement) {
          x.IsSelected = false;
          x.IsLinked = false;
          x.IsRequested = false;
          x.DocType = this.IndividualDocTypeId;
          res.push(x);
        }
      }
    })
    let BusinessDocs = this.searchLookUpData(this.BusinessDocTypeId);
    this.businessdocs = BusinessDocs;
    BusinessDocs.forEach(x => {
      if (x['Note']) {
        Obj = JSON.parse(x['Note']);
        if (Obj.MandatoryOnAgreement) {
          x.IsSelected = false;
          x.IsLinked = false;
          x.IsRequested = false;
          x.DocType = this.BusinessDocTypeId;
          res.push(x);
        }
      }
    })
    ///
    let Data = this.lookupData.find(x => x.ParentId == LookupEnums.LookupCategoryEnum.OtherIdentificationDocumentsParent);
    let otherPption = Data.LookUps.find(x => x.Id == LookupEnums.LookupCategoryEnum.OtherIdentificationDocuments);
    otherPption.IsSelected = false;
    otherPption.IsLinked = false;
    otherPption.IsRequested = false;
    otherPption.DocType = LookupEnums.LookupCategoryEnum.OtherIdentificationDocuments;
    res.push(otherPption)
    ///
    return res;
  }

  searchSerializeVar(data, var1) {
    let unSerizlizedData = JSON.parse(data);
    let findObj = unSerizlizedData.find(x => x.name == var1);
    return (!Utils.isNullOrUndefinedOrEmpty(findObj)) ? findObj['value'] : 'TTT';
  }

  getSerializedNumber(type: number, object: any): string {
    if (type == LookupEnums.BusinessIdentificationDocumentsEnum.CommercialCircular) {
      //object.CompanyFinancialNumber = "";
      //object.AuthorizedSignatoryName = "";
    }
    else if (type == LookupEnums.BusinessIdentificationDocumentsEnum.CompanyRegistration) {
      object.CompanyName = "";
      //object.Address = "";
      object.EstablishedDate = null;
      //object.CompanyType = "";
      //object.CompanySector = "";
      object.CompanyFinancialNumber = "";
      object.AuthorizedSignatoryName = "";

    }
    else if (Utils.isNullOrUndefinedOrEmpty(type)) {
      //object.
    }
    else {

    }
    return JSON.stringify(Object.entries(object)
      ?.filter(([name, value]) => value != "" && value != Guid.empty && value != null)
      ?.map(([name, value]) => ({ name, value })));
  }

  // setSystemRolesPermissions() {
  //   this.getSystemRolesPermissions().then((x)=>{
  //     this.systemRoles = x['Result'];
  //   })
  // }
  // getSystemRolesPermissions(withPermissions:boolean = true) {
  //   let params = {
  //     withPermissions:withPermissions
  //   }
  //   //GetRolesPermissions
  //   return this.http.get<any>(environment.apiUrl+'User/GetRoles',{params:params}).toPromise()
  // }


  /////
  getAttachment(docId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http
      .get<Blob>(`${this.apiUrl}` + 'Attachment/DownloadAttachment', { headers: headers, responseType: 'blob' as 'json', params: { attachmentId: docId, isArchived: false } });
    // const headers = new HttpHeaders({
    //   //'Content-Type': 'application/json',
    //   'Accept': '*/*'      
    // });
    // return this.http
    //   .get<Blob>(`${this.apiUrl}`+'Attachment/DownloadAttachment',{headers:headers,responseType:'blob' as 'json',params:{attachmentId:docId,isArchived:false}});


    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json'      
    // });
    // return this.http
    //   .get<Blob>(`${this.apiUrl}`+'Attachment/DownloadAttachment',{params:{attachmentId:docId,isArchived:false}});

  }
}
