import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './cookie-is-disabled.component.html',
  styleUrls: ['./cookie-is-disabled.component.css']
})
export class CookieIsDisabledComponent {

  constructor() { }

  // ngOnInit(): void {
  // }

}
