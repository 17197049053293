<div class="user-auth-top">
    <app-auth-nav></app-auth-nav> 
</div>
<img class="authentication-design" src="assets/images/authenticationBlocks-min.png">
<div class="user-auth-right">
    <div class="user-auth-form">
        <div class="heading-4">
            Your email is verified successfully.
        </div>
        <div class="clearspace"></div>
        <a *ngIf="!LoggedIn" class="btn-style-2" routerLink="/auth/login">Access your account</a>
        <a *ngIf="LoggedIn" class="btn-style-2" routerLink="/dashboard">Access your account</a>
    </div>
</div>
<div class="user-auth-left">
    <app-welcome></app-welcome>
</div>